import React from 'react';

import {
  Row,
  Col,
  CardDeck,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';
import classnames from 'classnames';

import styles from './skeleton.module.scss';
<div className={styles['body-comp-logo']}></div>;

const SkeletonTalksGridItems = () => {
  const counter = 8;

  return (
    <Row xs="1" md="3" lg="4" className={'g-4'} key={'skeleton-talks-row'}>
      {(() => {
        const SkeletonCard = [];
        for (let i = 1; i <= counter; i++) {
          SkeletonCard.push(
            <Col key={`SummitsGridItemSkeletonCol-` + i}>
              <CardDeck
                key={`SkeletonCardDeck-` + i}
                className={classnames('h-100')}
              >
                <Card
                  key={`SkeletonCard-` + i}
                  className={classnames('h-100', styles['skeleton-loading'])}
                >
                  <div
                    key={`SkeletonImg-wrap-` + i}
                    className={styles['img-wrap']}
                  ></div>

                  <CardBody
                    key={`SkeletonCardbody-` + i}
                    className={styles['cardbody']}
                  >
					<div key={`companyLogo`+ i} className={styles['body-comp-logo']}></div>
                    <div
                      key={`SkeletonMain-body-` + i}
                      className={styles['main-body']}
                    >

                      <CardTitle
                        key={`SkeletonItem-title-` + i}
                        className={styles['item-title']}
                      />
                      <CardText
                        key={`SkeletonCard-description-` + i}
                        className={classnames(styles['card-description'])}
                      />

                      <CardText
                        key={`SkeletonItem-meta-` + i}
                        className={styles['item-meta']}
                      />
                    </div>
                  </CardBody>
                </Card>
              </CardDeck>
            </Col>
          );
        }
        return SkeletonCard;
      })()}
    </Row>
  );
};

export default SkeletonTalksGridItems;
