import { Communication } from "../../components/TalksGrid/TalksGrid";
/**
 * Function which Returns the deduplication of talks.
 * Function Can be used for both initial (Single array) and load more (Two arrays) talks.
 *
 * @param newTalksData - New set of talks (Required)
 * @param oldTalksData - The old set of talks (Optional)
 * @returns The deduplication of talks.
 */
function removeDuplications(newTalksData: Communication[], oldTalksData?: Communication[]) {
  // This would store the final list of talks to filter the duplication.
  let listOfTalksToFilter: Communication[];

  // If oldTalksData has value then combined newTalksData and oldTalksData.
  if (oldTalksData?.length > 0) {
    // Combined newTalksData from oldTalksData.
    listOfTalksToFilter = [...oldTalksData, ...newTalksData];
  } else {
    listOfTalksToFilter = newTalksData
  }

  // oldTalksData has the old talks data and the newTalksData has the new set of talks.
  // Here, filtering listOfTalksToFilter and return the unique talks.
  const newSetOfTalks = listOfTalksToFilter?.filter(function({id}) {
    return !this[id] && (this[id] = id)
  }, {})

  return [...newSetOfTalks]
}

export default removeDuplications;
